import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts(ctx, params) {
      return useJwt.getContracts(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getContractAnalytics(params).then(response => response);
    },
    getContractDetail(ctx, id) {
      return useJwt.getContractDetail(id).then(response => response);
    },
    getContractParams() {
      return useJwt.getContractParams().then(response => response);
    },
    getContractHTML(ctx, id) {
      return useJwt.getContractHTML(id).then(response => response);
    },
    downloadContractPdf(ctx, id) {
      return useJwt.downloadContractPdf(id).then(response => response);
    },
    downloadContractDocuments(ctx, id) {
      return useJwt.downloadContractDocuments(id).then(response => response);
    },
    sendContractEmail(ctx, id) {
      return useJwt.sendContractEmail(id).then(response => response);
    },
    sendContractSigningEmail(ctx, id) {
      return useJwt.sendContractSigningEmail(id).then(response => response);
    },
    createContract(ctx, data) {
      return useJwt.createContract(data).then(response => response);
    },
    updateContract(ctx, data) {
      return useJwt.updateContract(data).then(response => response);
    },
    updateLeavingDate(ctx, data) {
      return useJwt.updateContractLeavingDate(data).then(response => response);
    },
    extendContract(ctx, data) {
      return useJwt.extendContract(data).then(response => response);
    },
    moveContract(ctx, data) {
      return useJwt.moveContract(data).then(response => response);
    },
    transferContract(ctx, data) {
      return useJwt.transferContract(data).then(response => response);
    },
    liquidContract(ctx, data) {
      return useJwt.liquidContract(data).then(response => response);
    },
    recoverContract(ctx, contractId) {
      return useJwt.recoverContract(contractId).then(response => response);
    },
    deleteContracts(ctx, data) {
      return useJwt.deleteContracts(data).then(response => response);
    },
    fetchTenants(ctx, params) {
      return useJwt.getTenants(params).then(response => response);
    },
    fetchFees(ctx, params) {
      return useJwt.getFees(params).then(response => response);
    },
    importContracts(ctx, data) {
      return useJwt.importContracts(data).then(response => response);
    },
    exportContracts(ctx, params) {
      return useJwt.exportContracts(params).then(response => response);
    },
    generateImportContractTemplate(ctx, params) {
      return useJwt.generateImportContractTemplate(params).then(response => response);
    },
    previewContract(ctx, data) {
      return useJwt.previewContract(data).then(response => response);
    },
    findContract(ctx, params) {
      return useJwt.findContract(params).then(response => response);
    },
    getContractSigningDocuments(ctx, params) {
      return useJwt.getContractSigningDocuments(params).then(response => response);
    },
    requestSigningOTP(ctx, data) {
      return useJwt.requestContractSigningOTP(data).then(response => response);
    },
    signContract(ctx, data) {
      return useJwt.signContract(data).then(response => response);
    },
    getRemainDiscount(ctx, data) {
      return useJwt.calculateRemainDiscount(data).then(response => response);
    },
    reCreateInvoiceForContract(ctx, data) {
      return useJwt.reCreateInvoiceForContract(data).then(response => response);
    },
  },
};
